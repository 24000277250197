import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Analytics event types
export type EventType = 'page_view' | 'click' | 'form_submit' | 'error' | 'performance';

interface AnalyticsEvent {
  type: EventType;
  name: string;
  properties?: Record<string, any>;
  timestamp?: number;
}

// Performance metrics interface
interface PerformanceMetrics {
  fcp: number; // First Contentful Paint
  lcp: number; // Largest Contentful Paint
  fid: number; // First Input Delay
  cls: number; // Cumulative Layout Shift
  ttfb: number; // Time to First Byte
}

class Analytics {
  private static instance: Analytics;
  private events: AnalyticsEvent[] = [];
  private errorCount = 0;
  private hasConsent = false;

  private constructor() {
    // Check initial consent
    this.hasConsent = localStorage.getItem('cookieConsent') === 'true';

    // Listen for consent changes
    window.addEventListener('cookieConsentChanged', () => {
      this.hasConsent = localStorage.getItem('cookieConsent') === 'true';
    });

    // Initialize error tracking
    window.addEventListener('error', this.handleError);
    window.addEventListener('unhandledrejection', this.handlePromiseError);
    
    // Initialize performance monitoring
    this.initializePerformanceMonitoring();
  }

  public static getInstance(): Analytics {
    if (!Analytics.instance) {
      Analytics.instance = new Analytics();
    }
    return Analytics.instance;
  }

  // Track events
  public trackEvent(event: AnalyticsEvent) {
    if (!this.hasConsent) return;

    const enrichedEvent = {
      ...event,
      timestamp: Date.now(),
      sessionId: this.getSessionId(),
      userId: this.getUserId(),
    };

    this.events.push(enrichedEvent);
    this.sendToAnalyticsServer(enrichedEvent);
  }

  // Error tracking
  private handleError = (error: ErrorEvent) => {
    this.errorCount++;
    this.trackEvent({
      type: 'error',
      name: 'js_error',
      properties: {
        message: error.message,
        filename: error.filename,
        lineno: error.lineno,
        colno: error.colno,
        stack: error.error?.stack,
      },
    });
  };

  private handlePromiseError = (event: PromiseRejectionEvent) => {
    this.errorCount++;
    this.trackEvent({
      type: 'error',
      name: 'unhandled_promise_rejection',
      properties: {
        reason: event.reason,
      },
    });
  };

  // Performance monitoring
  private initializePerformanceMonitoring() {
    if ('PerformanceObserver' in window) {
      // First Contentful Paint
      new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        if (entries.length > 0) {
          const fcp = entries[0];
          this.trackPerformanceMetric('fcp', fcp.startTime);
        }
      }).observe({ entryTypes: ['paint'] });

      // Largest Contentful Paint
      new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        if (entries.length > 0) {
          const lcp = entries[entries.length - 1];
          this.trackPerformanceMetric('lcp', lcp.startTime);
        }
      }).observe({ entryTypes: ['largest-contentful-paint'] });

      // First Input Delay
      new PerformanceObserver((entryList) => {
        const entries = entryList.getEntries();
        if (entries.length > 0) {
          const fid = entries[0];
          this.trackPerformanceMetric('fid', fid.processingStart - fid.startTime);
        }
      }).observe({ entryTypes: ['first-input'] });

      // Cumulative Layout Shift
      new PerformanceObserver((entryList) => {
        let cls = 0;
        for (const entry of entryList.getEntries()) {
          if (!entry.hadRecentInput) {
            cls += (entry as any).value;
          }
        }
        this.trackPerformanceMetric('cls', cls);
      }).observe({ entryTypes: ['layout-shift'] });
    }
  }

  private trackPerformanceMetric(metric: keyof PerformanceMetrics, value: number) {
    this.trackEvent({
      type: 'performance',
      name: metric,
      properties: { value },
    });
  }

  // A/B Testing
  private experimentGroups: Record<string, string> = {};

  public assignExperiment(experimentId: string, variants: string[]): string {
    if (!this.experimentGroups[experimentId]) {
      const randomVariant = variants[Math.floor(Math.random() * variants.length)];
      this.experimentGroups[experimentId] = randomVariant;
      
      this.trackEvent({
        type: 'page_view',
        name: 'experiment_assigned',
        properties: {
          experimentId,
          variant: randomVariant,
        },
      });
    }
    return this.experimentGroups[experimentId];
  }

  // User identification
  private getUserId(): string {
    if (!this.hasConsent) return 'anonymous';

    let userId = localStorage.getItem('userId');
    if (!userId) {
      userId = crypto.randomUUID();
      localStorage.setItem('userId', userId);
    }
    return userId;
  }

  private getSessionId(): string {
    if (!this.hasConsent) return 'anonymous-session';

    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = crypto.randomUUID();
      sessionStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
  }

  // Send data to analytics server
  private async sendToAnalyticsServer(event: AnalyticsEvent) {
    if (!this.hasConsent) return;

    try {
      await fetch('/api/analytics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(event),
      });
    } catch (error) {
      console.error('Failed to send analytics event:', error);
    }
  }
}

// Hook for tracking page views
export const usePageTracking = () => {
  const location = useLocation();
  
  useEffect(() => {
    const analytics = Analytics.getInstance();
    analytics.trackEvent({
      type: 'page_view',
      name: 'page_view',
      properties: {
        path: location.pathname,
        search: location.search,
      },
    });
  }, [location]);
};

// Export singleton instance
export const analytics = Analytics.getInstance();