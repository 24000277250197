// Dutch keywords
const dutchKeywords = [
  'AI bedrijf Nederland',
  'software op maat',
  'website specialist',
  'app developer Nederland',
  'AI expert',
  'machine learning specialist',
  'goedkoop website',
  'website laten maken',
  'AI optimalisatie',
  'bedrijf automatisering',
  'proces automatisering',
  'kunstmatige intelligentie bedrijf',
  'website ontwikkeling',
  'app ontwikkeling',
  'zakelijke automatisering',
  'AI consultancy Nederland',
  'software ontwikkeling',
  'maatwerk software',
  'bedrijfsprocessen optimaliseren',
  'digitale transformatie',
  'IT diensten Nederland',
];

// English keywords
const englishKeywords = [
  'Dutch AI company',
  'Netherlands software development',
  'custom AI solutions Netherlands',
  'enterprise software Netherlands',
  'AI consulting Benelux',
  'AI Integration',
  'Business Automation',
  'Custom Software Development',
  'Web Development',
  'App Development',
  'Enterprise AI Solutions',
  'Digital Transformation',
  'Machine Learning',
  'Process Automation',
  'AI Consulting',
  'Software Development Netherlands',
  'AI Development Bodegraven',
  'Custom AI Solutions',
  'Business Process Optimization',
  'Enterprise Software Development',
];

export const metaKeywords = [...dutchKeywords, ...englishKeywords];