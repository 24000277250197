import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Services } from './pages/Services';
import { Sitemap } from './pages/Sitemap';
import { ErrorBoundary } from './components/ErrorBoundary';

const Root = () => (
  <Layout>
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  </Layout>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { index: true, element: <Home /> },
      { path: 'about', element: <About /> },
      { path: 'contact', element: <Contact /> },
      { path: 'services', element: <Services /> },
      { path: 'sitemap', element: <Sitemap /> },
    ],
  },
]);

export const Router = () => <RouterProvider router={router} />;