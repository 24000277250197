import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useBreadcrumbSchema = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  
  const paths = pathname.split('/').filter(Boolean);
  
  const breadcrumbList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://aetherflare.nl'
      }
    ]
  };

  paths.forEach((path, index) => {
    breadcrumbList.itemListElement.push({
      '@type': 'ListItem',
      position: index + 2,
      name: t(`nav.${path}`),
      item: `https://aetherflare.nl/${paths.slice(0, index + 1).join('/')}`
    });
  });

  return breadcrumbList;
};