import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en';
import nl from './locales/nl';

const getSupportedLanguage = () => {
  // Get browser language (e.g., 'en-US' -> 'en')
  const browserLang = navigator.language.split('-')[0];
  
  // Check if browser language is supported
  if (['en', 'nl'].includes(browserLang)) {
    return browserLang;
  }
  
  return 'en'; // Default to English
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      nl,
    },
    lng: getSupportedLanguage(),
    fallbackLng: 'en',
    supportedLngs: ['en', 'nl'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;