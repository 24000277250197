import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Sitemap = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to sitemap.xml
    window.location.href = '/sitemap.xml';
  }, []);

  return null;
};