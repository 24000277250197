import { ReactNode, useState, useEffect } from 'react';
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { FlameIntro } from './FlameIntro';
import { AnalyticsProvider } from './AnalyticsProvider';
import { CookieConsent } from './CookieConsent';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const [showIntro, setShowIntro] = useState(true);
  const [hasSeenIntro, setHasSeenIntro] = useState(() => {
    return sessionStorage.getItem('hasSeenIntro') === 'true';
  });

  useEffect(() => {
    if (hasSeenIntro) {
      setShowIntro(false);
    }
  }, [hasSeenIntro]);

  const handleIntroComplete = () => {
    setShowIntro(false);
    sessionStorage.setItem('hasSeenIntro', 'true');
    setHasSeenIntro(true);
  };

  return (
    <AnalyticsProvider>
      <div className="flex flex-col min-h-screen">
        {showIntro && !hasSeenIntro && <FlameIntro onComplete={handleIntroComplete} />}
        <Navbar />
        <main id="main-content" tabIndex={-1} className="flex-grow pt-16 focus:outline-none">{children}</main>
        <Footer />
        <CookieConsent />
      </div>
    </AnalyticsProvider>
  );
};