import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Brain, Rocket, Globe, ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { SEO } from '../../components/SEO';
import { useBreadcrumbSchema } from '../../utils/seo/breadcrumbs';
import { analytics } from '../../utils/analytics';

export const Services = () => {
  const { t } = useTranslation();
  const breadcrumbSchema = useBreadcrumbSchema();
  const location = useLocation();
  const [expandedService, setExpandedService] = useState<'ai' | 'app' | 'web' | null>(null);
  
  // Refs for each section
  const aiRef = useRef<HTMLElement>(null);
  const appRef = useRef<HTMLElement>(null);
  const webRef = useRef<HTMLElement>(null);

  useEffect(() => {
    // Get the hash without the #
    const hash = location.hash.slice(1);
    
    // Map of hash to refs
    const refs = {
      ai: aiRef,
      app: appRef,
      web: webRef
    };

    // If we have a matching ref, scroll to it
    if (hash && refs[hash as keyof typeof refs]?.current) {
      const ref = refs[hash as keyof typeof refs].current;
      if (ref) {
        // Add a small delay to ensure the page is fully loaded
        setTimeout(() => {
          ref.scrollIntoView({ behavior: 'smooth' });
          // Expand the section
          setExpandedService(hash as 'ai' | 'app' | 'web');
        }, 100);
      }
    }
  }, [location.hash]);

  const solutions = {
    ai: [
      { key: 'consultation', icon: '🤝' },
      { key: 'training', icon: '📚' },
      { key: 'strategy', icon: '🎯' },
      { key: 'automation', icon: '⚡' },
      { key: 'agents', icon: '🤖' },
      { key: 'gdpr', icon: '🔒' },
      { key: 'models', icon: '🧠' },
    ],
    app: [
      { key: 'mobile', icon: '📱' },
      { key: 'web', icon: '💻' },
      { key: 'desktop', icon: '🖥️' },
      { key: 'cloud', icon: '☁️' },
      { key: 'security', icon: '🔒' },
      { key: 'integration', icon: '🔄' },
    ],
    web: [
      { key: 'responsive', icon: '📱' },
      { key: 'seo', icon: '🔍' },
      { key: 'performance', icon: '⚡' },
      { key: 'analytics', icon: '📊' },
      { key: 'accessibility', icon: '♿' },
      { key: 'security', icon: '🔒' },
    ],
  };

  const toggleService = (service: 'ai' | 'app' | 'web') => {
    analytics.trackEvent({
      type: 'click',
      name: 'toggle_service_solutions',
      properties: {
        service,
        action: expandedService === service ? 'collapse' : 'expand'
      }
    });
    setExpandedService(expandedService === service ? null : service);
  };

  return (
    <div className="pt-24 pb-16 bg-gradient-to-b from-gray-50/50 via-white to-gray-50/50 dark:from-gray-900 dark:via-gray-800/50 dark:to-gray-900">
      <SEO 
        title={t('services.title')}
        description={`${t('services.ai.description')}. ${t('services.app.description')}. ${t('services.web.description')}`}
        schema={breadcrumbSchema}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-6xl font-bold text-gray-900 dark:text-white mb-16 text-center">
          {t('services.title')}
        </h1>

        <div className="space-y-24">
          {/* AI Service */}
          <section ref={aiRef} id="ai" className="relative scroll-mt-24">
            <div className="absolute inset-0 bg-white/80 dark:bg-gray-800/30 backdrop-blur-sm rounded-3xl -z-10" />
            <div className="grid md:grid-cols-2 gap-12 items-start max-w-6xl mx-auto">
            <div className="relative">
              <div className="relative">
                <Brain className="w-16 h-16 text-red-600/90 dark:text-red-500/90 mb-6 relative z-10 animate-float" />
              </div>
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                {t('services.ai.title')}
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
                {t('services.ai.fullDescription')}
              </p>
              <button
                onClick={() => toggleService('ai')}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-600 dark:text-red-500 hover:text-red-700 dark:hover:text-red-400 mb-4 transition-colors"
              >
                {expandedService === 'ai' ? (
                  <ChevronUp className="w-4 h-4 mr-2" />
                ) : (
                  <ChevronDown className="w-4 h-4 mr-2" />
                )}
                {expandedService === 'ai' ? t('services.solutions.hide') : t('services.solutions.view')}
              </button>
              <AnimatePresence>
                {expandedService === 'ai' && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="overflow-hidden"
                  >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                      {solutions.ai.map((solution) => (
                        <div
                          key={solution.key}
                          className="p-4 bg-white/80 dark:bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-100/50 dark:border-gray-700/50 hover:border-red-500/30 dark:hover:border-red-500/30 transition-all duration-300 shadow-sm hover:shadow-md"
                        >
                          <div className="flex items-start space-x-4">
                            <span className="text-2xl">{solution.icon}</span>
                            <div>
                              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                                {t(`services.ai.solutions.${solution.key}.title`)}
                              </h4>
                              <p className="text-gray-600 dark:text-gray-300 text-sm">
                                {t(`services.ai.solutions.${solution.key}.description`)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <Link
                to="/contact?service=ai"
                className="inline-flex items-center px-6 py-3 text-base font-medium rounded-lg text-white bg-red-600 hover:bg-red-700 transition-all duration-300 shadow-sm hover:shadow-lg"
              >
                {t('services.ai.cta')}
              </Link>
            </div>
            <div className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm shadow-lg p-8 rounded-lg sticky top-24 border border-gray-100/50 dark:border-gray-700/50">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                {t('services.ai.benefits.title')}
              </h3>
              <ul className="space-y-4 text-gray-600 dark:text-gray-300">
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.ai.benefits.analysis')}
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.ai.benefits.processes')}
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.ai.benefits.decisions')}
                </li>
              </ul>
            </div>
            </div>
          </section>

          {/* App Development Service */}
          <section ref={appRef} id="app" className="relative py-12 scroll-mt-24">
            <div className="absolute inset-0 bg-white/80 dark:bg-gray-800/30 backdrop-blur-sm rounded-3xl -z-10" />
            <div className="grid md:grid-cols-2 gap-12 items-start max-w-6xl mx-auto">
            <div className="md:order-2">
              <Rocket className="w-16 h-16 text-red-600/90 dark:text-red-500/90 mb-6 animate-float" />
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                {t('services.app.title')}
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
                {t('services.app.fullDescription')}
              </p>
              <button
                onClick={() => toggleService('app')}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-600 hover:text-red-700 mb-4 transition-colors"
              >
                {expandedService === 'app' ? (
                  <ChevronUp className="w-4 h-4 mr-2" />
                ) : (
                  <ChevronDown className="w-4 h-4 mr-2" />
                )}
                {expandedService === 'app' ? t('services.solutions.hide') : t('services.solutions.view')}
              </button>
              <AnimatePresence>
                {expandedService === 'app' && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="overflow-hidden"
                  >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                      {solutions.app.map((solution) => (
                        <div
                          key={solution.key}
                          className="p-4 bg-white/80 dark:bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-100/50 dark:border-gray-700/50 hover:border-red-500/30 dark:hover:border-red-500/30 transition-all duration-300 shadow-sm hover:shadow-md"
                        >
                          <div className="flex items-start space-x-4">
                            <span className="text-2xl">{solution.icon}</span>
                            <div>
                              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                                {t(`services.app.solutions.${solution.key}.title`)}
                              </h4>
                              <p className="text-gray-600 dark:text-gray-300 text-sm">
                                {t(`services.app.solutions.${solution.key}.description`)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <Link
                to="/contact?service=app"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
              >
                {t('services.app.cta')}
              </Link>
            </div>
            <div className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm shadow-lg p-8 rounded-lg md:order-1 sticky top-24 border border-gray-100/50 dark:border-gray-700/50">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                {t('services.app.process.title')}
              </h3>
              <ul className="space-y-4 text-gray-600 dark:text-gray-300">
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.app.process.consultation')}
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.app.process.development')}
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.app.process.deployment')}
                </li>
              </ul>
            </div>
            </div>
          </section>

          {/* Web Development Service */}
          <section ref={webRef} id="web" className="relative mb-24 scroll-mt-24">
            <div className="absolute inset-0 bg-white/80 dark:bg-gray-800/30 backdrop-blur-sm rounded-3xl -z-10" />
            <div className="grid md:grid-cols-2 gap-12 items-start max-w-6xl mx-auto">
            <div>
              <Globe className="w-16 h-16 text-red-600/90 dark:text-red-500/90 mb-6 animate-float" />
              <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
                {t('services.web.title')}
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
                {t('services.web.fullDescription')}
              </p>
              <button
                onClick={() => toggleService('web')}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-red-600 hover:text-red-700 mb-4 transition-colors"
              >
                {expandedService === 'web' ? (
                  <ChevronUp className="w-4 h-4 mr-2" />
                ) : (
                  <ChevronDown className="w-4 h-4 mr-2" />
                )}
                {expandedService === 'web' ? t('services.solutions.hide') : t('services.solutions.view')}
              </button>
              <AnimatePresence>
                {expandedService === 'web' && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="overflow-hidden"
                  >
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                      {solutions.web.map((solution) => (
                        <div
                          key={solution.key}
                          className="p-4 bg-white/80 dark:bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-100/50 dark:border-gray-700/50 hover:border-red-500/30 dark:hover:border-red-500/30 transition-all duration-300 shadow-sm hover:shadow-md"
                        >
                          <div className="flex items-start space-x-4">
                            <span className="text-2xl">{solution.icon}</span>
                            <div>
                              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                                {t(`services.web.solutions.${solution.key}.title`)}
                              </h4>
                              <p className="text-gray-600 dark:text-gray-300 text-sm">
                                {t(`services.web.solutions.${solution.key}.description`)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
              <Link
                to="/contact?service=web"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
              >
                {t('services.web.cta')}
              </Link>
            </div>
            <div className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm shadow-lg p-8 rounded-lg sticky top-24 border border-gray-100/50 dark:border-gray-700/50">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                {t('services.web.features.title')}
              </h3>
              <ul className="space-y-4 text-gray-600 dark:text-gray-300">
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.web.features.responsive')}
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.web.features.seo')}
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-red-600 rounded-full mr-3" />
                  {t('services.web.features.performance')}
                </li>
              </ul>
            </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};