import { useTranslation } from 'react-i18next';
import { Target, Lightbulb, Rocket, Users, Code, HandshakeIcon } from 'lucide-react';
import { motion } from 'framer-motion';
import { SEO } from '../components/SEO';
import { useBreadcrumbSchema } from '../utils/seo/breadcrumbs';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  whileInView: { opacity: 1, y: 0 },
  viewport: { once: true },
  transition: { duration: 0.6 }
};

export const About = () => {
  const { t } = useTranslation();
  const breadcrumbSchema = useBreadcrumbSchema();

  return (
    <div className="pt-24 pb-16">
      <SEO 
        title={t('about.title')}
        description={t('about.description')}
        schema={breadcrumbSchema}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
          variants={fadeIn}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-6">
            {t('about.title')}
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            {t('about.description')}
          </p>
        </motion.div>

        {/* Mission & Vision */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-24">
          <motion.div
            variants={fadeIn}
            initial="initial"
            whileInView="whileInView"
            viewport={{ once: true }}
            className="bg-gradient-to-br from-gray-50 to-white dark:from-gray-800 dark:to-gray-900 p-8 rounded-lg shadow-lg relative overflow-hidden group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-red-100/20 to-red-50/20 dark:from-red-900/20 dark:to-red-800/20 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
            <Target className="w-12 h-12 text-red-600 mb-6 transform group-hover:scale-110 transition-transform" />
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              {t('about.mission.title')}
            </h2>
            <p className="text-gray-600 dark:text-gray-300 relative">
              {t('about.mission.description')}
            </p>
          </motion.div>

          <motion.div
            variants={fadeIn}
            initial="initial"
            whileInView="whileInView"
            viewport={{ once: true }}
            className="bg-gradient-to-br from-gray-50 to-white dark:from-gray-800 dark:to-gray-900 p-8 rounded-lg shadow-lg relative overflow-hidden group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-red-100/20 to-red-50/20 dark:from-red-900/20 dark:to-red-800/20 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
            <Lightbulb className="w-12 h-12 text-red-600 mb-6 transform group-hover:scale-110 transition-transform" />
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              {t('about.vision.title')}
            </h2>
            <p className="text-gray-600 dark:text-gray-300 relative">
              {t('about.vision.description')}
            </p>
          </motion.div>
        </div>

        {/* Values */}
        <motion.div
          variants={fadeIn}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
          className="mb-24"
        >
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white text-center mb-12">
            {t('about.values.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
              <Rocket className="w-10 h-10 text-red-600 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                {t('about.values.innovation.title')}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {t('about.values.innovation.description')}
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
              <Code className="w-10 h-10 text-red-600 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                {t('about.values.excellence.title')}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {t('about.values.excellence.description')}
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
              <HandshakeIcon className="w-10 h-10 text-red-600 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                {t('about.values.partnership.title')}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {t('about.values.partnership.description')}
              </p>
            </div>
          </div>
        </motion.div>

        {/* Team */}
        <motion.div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-lg shadow-lg">
          <Users className="w-12 h-12 text-red-600 mx-auto mb-4" />
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white text-center mb-8">
            {t('about.team.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Julian Cooper
              </h3>
              <p className="text-red-600 font-medium mb-4">
                AI & Technical Lead
              </p>
              <p className="text-gray-600 dark:text-gray-300">
                Full-stack developer specializing in AI integration and technical architecture. Successfully implemented automation solutions for multiple businesses, focusing on practical AI applications and clean, efficient solutions that deliver real value.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-900 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Roman Fraikin
              </h3>
              <p className="text-red-600 font-medium mb-4">
                Technical Lead
              </p>
              <p className="text-gray-600 dark:text-gray-300">
                Full-stack developer with a passion for clean architecture and efficient solutions. Has helped several companies modernize their tech stack and improve operational efficiency.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};