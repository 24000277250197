import { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageSwitch } from './LanguageSwitch';

interface MobileNavProps {
  isOpen: boolean;
  onToggle: () => void;
}

export const MobileNav = ({ isOpen, onToggle }: MobileNavProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isActive = (path: string) => pathname === path;

  return (
    <div className="md:hidden">
      <button
        onClick={onToggle}
        className="p-2 rounded-md text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
        aria-label="Toggle menu"
      >
        {isOpen ? (
          <X className="w-6 h-6" />
        ) : (
          <Menu className="w-6 h-6" />
        )}
      </button>

      {isOpen && (
        <div className="absolute top-16 left-0 right-0 bg-white dark:bg-gray-900 border-b border-gray-100 dark:border-gray-800 py-4 px-4 space-y-2 shadow-lg">
          <NavLink to="/" active={isActive('/')} onClick={onToggle}>
            {t('nav.home')}
          </NavLink>
          <NavLink to="/services" active={isActive('/services')} onClick={onToggle}>
            {t('nav.services')}
          </NavLink>
          <NavLink to="/about" active={isActive('/about')} onClick={onToggle}>
            {t('nav.about')}
          </NavLink>
          <NavLink to="/contact" active={isActive('/contact')} onClick={onToggle}>
            {t('nav.contact')}
          </NavLink>
          <div className="px-4 py-2">
            <LanguageSwitch />
          </div>
        </div>
      )}
    </div>
  );
};

interface NavLinkProps {
  to: string;
  active: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

const NavLink = ({ to, active, children, onClick }: NavLinkProps) => (
  <Link
    to={to}
    onClick={onClick}
    className={`block py-3 px-4 rounded-md transition-colors ${
      active
        ? 'text-red-600 dark:text-red-500 bg-gray-50 dark:bg-gray-800'
        : 'text-gray-700 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-500 hover:bg-gray-50 dark:hover:bg-gray-800'
    }`}
  >
    {children}
  </Link>
);