import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingSpinner } from './ui/LoadingSpinner';
import { useToast } from '../hooks/useToast';
import { analytics } from '../utils/analytics';

const schema = z.object({
  name: z.string().min(2),
  email: z.string().email(),
  company: z.string().min(2),
  type: z.enum(['ai', 'app', 'web', 'quickscan']),
  message: z.string().min(10),
});

type FormData = z.infer<typeof schema>;

interface ContactFormProps {
  initialService?: 'ai' | 'app' | 'web';
}

export const ContactForm: React.FC<ContactFormProps> = memo(({ initialService }) => {
  // useTranslation will automatically pick up the currently active locale 
  // from your i18n configuration (e.g., 'en', 'en-GB', 'nl', etc.)
  const { t } = useTranslation();
  
  const { addToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      type: initialService || 'ai',
    },
    mode: 'onChange',
  });

  const onSubmit = async (data: FormData) => {
    try {
      setIsSubmitting(true);

      // Track that the user submitted the Contact form
      analytics.trackEvent({
        type: 'form_submit',
        name: 'contact_form',
        properties: {
          formType: data.type,
        },
      });

      // Post to /api/email/contact
      const response = await fetch('/api/email/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.success) {
        addToast(t('form.success'), 'success');
        reset();

        // Track successful contact form submission
        analytics.trackEvent({
          type: 'form_submit',
          name: 'contact_form_success',
          properties: {
            formType: data.type,
          },
        });
      } else {
        addToast(t('form.error'), 'error');

        // Track contact form error
        analytics.trackEvent({
          type: 'error',
          name: 'contact_form_error',
          properties: {
            formType: data.type,
            error: 'API Error',
          },
        });
      }
    } catch (error) {
      console.error('Error sending email:', error);
      addToast(t('form.error'), 'error');

      // Track contact form error (network/other)
      analytics.trackEvent({
        type: 'error',
        name: 'contact_form_error',
        properties: {
          formType: data.type,
          error: error instanceof Error ? error.message : 'Unknown error',
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 max-w-xl mx-auto">
      {/* Name */}
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          {t('form.name')}
        </label>
        <input
          id="name"
          {...register('name')}
          aria-invalid={!!errors.name}
          aria-describedby={errors.name ? 'name-error' : undefined}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500
                     dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100"
        />
        {errors.name && (
          <p id="name-error" role="alert" className="mt-1 text-sm text-red-600">
            {errors.name.message}
          </p>
        )}
      </div>

      {/* Email */}
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          {t('form.email')}
        </label>
        <input
          id="email"
          type="email"
          {...register('email')}
          aria-invalid={!!errors.email}
          aria-describedby={errors.email ? 'email-error' : undefined}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500
                     dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100"
        />
        {errors.email && (
          <p id="email-error" role="alert" className="mt-1 text-sm text-red-600">
            {errors.email.message}
          </p>
        )}
      </div>

      {/* Company */}
      <div>
        <label
          htmlFor="company"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          {t('form.company')}
        </label>
        <input
          id="company"
          {...register('company')}
          aria-invalid={!!errors.company}
          aria-describedby={errors.company ? 'company-error' : undefined}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500
                     dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100"
        />
        {errors.company && (
          <p id="company-error" role="alert" className="mt-1 text-sm text-red-600">
            {errors.company.message}
          </p>
        )}
      </div>

      {/* Type */}
      <div>
        <label
          htmlFor="type"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          {t('form.type.label')}
        </label>
        <select
          id="type"
          {...register('type')}
          aria-invalid={!!errors.type}
          aria-describedby={errors.type ? 'type-error' : undefined}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500
                     dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100"
        >
          <option value="ai">{t('form.type.ai')}</option>
          <option value="app">{t('form.type.app')}</option>
          <option value="web">{t('form.type.web')}</option>
          <option value="quickscan">{t('form.type.quickscan')}</option>
        </select>
        {errors.type && (
          <p id="type-error" role="alert" className="mt-1 text-sm text-red-600">
            {errors.type.message}
          </p>
        )}
      </div>

      {/* Message */}
      <div>
        <label
          htmlFor="message"
          className="block text-sm font-medium text-gray-700 dark:text-gray-300"
        >
          {t('form.message')}
        </label>
        <textarea
          id="message"
          {...register('message')}
          aria-invalid={!!errors.message}
          aria-describedby={errors.message ? 'message-error' : undefined}
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500
                     dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100"
        />
        {errors.message && (
          <p id="message-error" role="alert" className="mt-1 text-sm text-red-600">
            {errors.message.message}
          </p>
        )}
      </div>

      {/* Privacy Statement */}
      <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
        {t('form.privacyStatement')}
      </p>

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isSubmitting}
        aria-busy={isSubmitting}
        className="w-full flex justify-center py-2 px-4 border border-transparent
                   rounded-md shadow-sm text-sm font-medium text-white bg-red-600
                   hover:bg-red-700 focus:outline-none focus:ring-2
                   focus:ring-offset-2 focus:ring-red-500
                   dark:focus:ring-offset-gray-900"
      >
        {isSubmitting ? (
          <>
            <LoadingSpinner className="mr-2" />
            {t('form.submitting')}
          </>
        ) : (
          t('form.submit')
        )}
      </button>
    </form>
  );
});

ContactForm.displayName = 'ContactForm';
