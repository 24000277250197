import { motion } from 'framer-motion';
import { Logo } from './Logo';

interface FlameIntroProps {
  onComplete: () => void;
}
import { useReducedMotion } from '../hooks/useReducedMotion';

export const FlameIntro: React.FC<FlameIntroProps> = ({ onComplete }) => {
  const prefersReducedMotion = useReducedMotion();

  const animationDuration = prefersReducedMotion ? 0.3 : 2;

  return (
    <motion.div
      className="fixed inset-0 bg-gradient-to-b from-black to-gray-900 z-50 flex items-center justify-center overflow-hidden"
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: animationDuration }}
      onAnimationComplete={onComplete}
      role="presentation"
      aria-hidden="true"
    >
      <div className="relative">
        {/* Main flame core */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-t from-red-600/0 via-red-600/70 to-orange-400/20 blur-md"
          initial={{ scale: 0, opacity: 0 }}
          animate={{
            scale: [0, 1.5, 1.2],
            opacity: [0, 0.9, 0],
            rotate: [0, -10, 10]
          }}
          transition={{
            duration: 2,
            times: [0, 0.7, 1],
            ease: "easeInOut"
          }}
        />
        
        {/* Outer flame glow */}
        <motion.div
          className="absolute inset-0 bg-gradient-to-t from-orange-500/0 via-yellow-400/40 to-red-500/10 blur-lg"
          initial={{ scale: 0, opacity: 0 }}
          animate={{
            scale: [0, 1.3, 1],
            opacity: [0, 0.7, 0],
            rotate: [0, 10, -10]
          }}
          transition={{
            duration: 1.8,
            delay: 0.2,
            times: [0, 0.6, 1],
            ease: "easeInOut"
          }}
        />

        {/* Ember particles */}
        <motion.div
          className="absolute inset-0"
          initial="initial"
          animate="animate"
        >
          {[...Array(6)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-1 h-1 bg-yellow-400 rounded-full"
              initial={{
                x: "50%",
                y: "50%",
                scale: 0,
                opacity: 0
              }}
              animate={{
                x: `${50 + (Math.random() * 100 - 50)}%`,
                y: `${30 + (Math.random() * 20)}%`,
                scale: [0, 1, 0],
                opacity: [0, 0.8, 0]
              }}
              transition={{
                duration: 1.5,
                delay: 0.2 + i * 0.1,
                ease: "easeOut",
                times: [0, 0.7, 1]
              }}
            />
          ))}
        </motion.div>

        {/* Logo */}
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: [0.8, 1.1, 1], opacity: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.3,
            times: [0, 0.6, 1],
            ease: "easeOut"
          }}
        >
          <Logo className="w-32 h-32 relative z-10" />
        </motion.div>

        {/* Text */}
        <motion.div
          className="absolute top-full left-1/2 -translate-x-1/2 mt-4 text-white text-3xl font-bold tracking-wider"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: [0, 1], y: [20, 0] }}
          transition={{
            duration: 0.6,
            delay: 0.5,
            ease: "easeOut"
          }}
        >
          AetherFlare
        </motion.div>
      </div>
    </motion.div>
  );
};