import { useState, useEffect } from 'react';

export const useDarkMode = () => {
  // Initialize state based on system preference
  const [isDark, setIsDark] = useState(() => {
    if (typeof window === 'undefined') return false;
    
    const saved = localStorage.getItem('darkMode');
    if (saved !== null) {
      return JSON.parse(saved);
    }
    
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    // Update class on document
    document.documentElement.classList.toggle('dark', isDark);
    
    // Save preference
    localStorage.setItem('darkMode', JSON.stringify(isDark));

    // Listen for system preference changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent) => {
      const hasStoredPreference = localStorage.getItem('darkMode') !== null;
      if (!hasStoredPreference) {
        setIsDark(e.matches);
      }
    };

    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, [isDark]);

  return [isDark, setIsDark] as const;
};