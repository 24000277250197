import React from 'react';

interface LogoProps {
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className = 'w-12 h-12' }) => (
  <svg
    className={className}
    role="img"
    aria-label="AetherFlare logo"
    viewBox="0 0 430 426"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0.000000,426.000000) scale(0.100000,-0.100000)" fill="rgb(220, 38, 37)">
      <path d="M2070 3903 c-40 -87 -92 -198 -116 -248 -23 -49 -61 -130 -84 -180 -23 -49 -111 -238 -195 -420 -84 -181 -174 -375 -200 -430 -26 -55 -63 -134 -82 -175 -19 -41 -75 -160 -123 -265 -48 -104 -113 -243 -144 -308 -31 -65 -56 -122 -56 -127 0 -6 129 -10 340 -10 232 0 340 3 340 11 0 5 10 31 21 57 12 26 55 121 96 212 41 91 86 190 100 220 14 30 57 126 97 214 39 87 75 159 80 160 6 0 83 -159 172 -354 89 -195 179 -392 200 -437 l38 -83 334 0 333 0 -7 22 c-4 13 -28 68 -54 123 -26 55 -88 188 -138 295 -134 290 -176 381 -267 575 -26 55 -87 186 -135 290 -48 105 -107 231 -130 280 -23 50 -52 113 -65 140 -13 28 -47 100 -75 160 -29 61 -78 166 -110 235 -31 69 -66 142 -76 163 l-20 39 -74 -159z" />
      <path d="M1622 1462 c-67 -147 -122 -273 -122 -279 0 -10 137 -13 650 -13 l650 0 -14 33 c-8 17 -58 129 -111 247 -53 118 -103 230 -111 248 l-14 32 -403 0 -402 -1 -123 -267z" />
      <path d="M792 1158 c-9 -11 -126 -263 -352 -758 l-57 -125 345 -3 c190 -1 349 1 353 5 7 7 29 56 126 278 20 44 46 103 58 130 89 197 205 465 205 474 0 17 -664 15 -678 -1z" />
      <path d="M2820 1159 c0 -7 30 -77 66 -158 36 -80 74 -164 84 -186 56 -126 154 -344 167 -370 8 -16 28 -63 45 -103 l30 -72 349 0 c192 0 349 3 349 6 0 7 -103 230 -230 499 -29 61 -79 169 -112 240 -33 72 -64 136 -70 143 -14 17 -678 18 -678 1z" />
    </g>
  </svg>
);