import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { generateOrganizationSchema } from './schema';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
}

export const defaultSEO = {
  titleTemplate: '%s | AetherFlare',
  defaultTitle: 'AetherFlare - AI & Automation Solutions',
  defaultDescription: 'Transform your business with AI-powered automation solutions. Expert consultation and implementation services for AI integration, app development, and web solutions.',
  siteUrl: 'https://aetherflare.nl',
  defaultImage: 'https://aetherflare.nl/og-image.jpg',
  twitterHandle: '@aetherflare',
};

export const useSEO = ({ title, description, image, type = 'website' }: SEOProps = {}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  
  const seo = {
    title: title ? defaultSEO.titleTemplate.replace('%s', title) : defaultSEO.defaultTitle,
    description: description || defaultSEO.defaultDescription,
    image: image || defaultSEO.defaultImage,
    url: `${defaultSEO.siteUrl}${pathname}`,
    type,
  };

  const jsonLd = generateOrganizationSchema();

  return { seo, jsonLd };
};