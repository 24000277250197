import { Link, useLocation } from 'react-router-dom';
import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from './Logo';
import { LanguageSwitch } from './LanguageSwitch';
import { ThemeToggle } from './ThemeToggle';
import { MobileNav } from './MobileNav';

export const Navbar = memo(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActive = (path: string) => pathname === path;

  return (
    <nav
      className="fixed w-full bg-white/95 dark:bg-gray-900/95 backdrop-blur-sm z-50 border-b border-gray-100/50 dark:border-gray-800/50"
      role="navigation"
      aria-label="Main navigation"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link 
              to="/" 
              className="flex items-center group" 
              aria-label={t('accessibility.logoHomeLink')}
            >
              <Logo className="w-8 h-8 transition-transform group-hover:scale-105" />
              <span className="ml-2 text-xl font-bold text-gray-900 dark:text-white transition-colors group-hover:text-red-600 dark:group-hover:text-red-500">AetherFlare</span>
            </Link>
            <div className="hidden md:flex ml-10 space-x-8">
              <NavLink to="/" active={isActive('/')}>{t('nav.home')}</NavLink>
              <NavLink to="/services" active={isActive('/services')}>{t('nav.services')}</NavLink>
              <NavLink to="/about" active={isActive('/about')}>{t('nav.about')}</NavLink>
              <NavLink to="/contact" active={isActive('/contact')}>{t('nav.contact')}</NavLink>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <ThemeToggle />
            <div className="hidden md:block">
              <LanguageSwitch />
            </div>
            <MobileNav isOpen={isMenuOpen} onToggle={() => setIsMenuOpen(!isMenuOpen)} />
          </div>
        </div>
      </div>
    </nav>
  );
});

Navbar.displayName = 'Navbar';

interface NavLinkProps {
  to: string;
  active: boolean;
  children: React.ReactNode;
}

const NavLink = ({ to, active, children }: NavLinkProps) => (
  <Link
    to={to}
    className={`${
      active
        ? 'text-red-600 dark:text-red-500 relative after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-red-600 dark:after:bg-red-500'
        : 'text-gray-700 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-500 relative after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-red-600 dark:after:bg-red-500 after:scale-x-0 hover:after:scale-x-100 after:transition-transform'
    } px-3 py-2 text-sm font-medium transition-all duration-200`}
  >
    {children}
  </Link>
);