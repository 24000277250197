export default {
  translation: {
    'nav.home': 'Home',
    'nav.services': 'Diensten',
    'nav.about': 'Over Ons',
    'nav.contact': 'Contact',
    
    'hero.title': 'Bedrijven Transformeren met AI & Automatisering',
    'hero.subtitle': 'Ontdek het potentieel van AI in uw bedrijf met onze expertconsultatie en ontwikkelingsdiensten',
    'hero.cta': 'Begin Nu',
    
    'ai.transform.title': 'Transformeer Uw Bedrijf met AI-Gestuurde Automatisering',
    'ai.transform.subtitle': 'Wij bouwen uw digitale werknemers, zodat u zich kunt concentreren op wat echt belangrijk is. Ontvang uw gratis AI-Scan rapport om te ontdekken hoe automatisering uw bedrijfsprocessen kan transformeren.',
    'ai.benefits.title': 'Waarom Kiezen voor AI Automatisering?',
    'ai.benefits.time': 'Bespaar tijd en verminder kosten',
    'ai.benefits.focus': 'Focus op wat echt belangrijk is',
    'ai.benefits.scale': 'Groei uw bedrijf zonder extra personeel',
    'ai.benefits.roadmap': 'Ontvang een gratis gepersonaliseerd implementatieplan',
    'ai.benefits.cta': 'Ontvang Uw Gratis AI-Scan',
    
    'services.title': 'Onze Diensten',
    'services.ai.title': 'AI Integratie',
    'services.ai.description': 'We analyseren uw bedrijfsprocessen om optimale AI-implementatiemogelijkheden te vinden',
    'services.ai.fullDescription': 'Transformeer uw bedrijfsprocessen met geavanceerde AI-oplossingen op maat. Ons expertteam analyseert uw processen en identificeert belangrijke gebieden waar AI de grootste impact kan hebben.',
    'services.ai.cta': 'Start AI Integratie',
    'services.ai.benefits.title': 'Voordelen van AI Integratie',
    'services.ai.benefits.analysis': 'Geautomatiseerde data-analyse en inzichten',
    'services.ai.benefits.processes': 'Gestroomlijnde bedrijfsprocessen',
    'services.ai.benefits.decisions': 'Verbeterde besluitvorming',
    'services.ai.solutions.title': 'Onze AI Oplossingen',
    'services.ai.solutions.consultation.title': 'Expert Consultatie',
    'services.ai.solutions.consultation.description': 'Krijg persoonlijk advies van onze AI-experts om de beste mogelijkheden voor uw bedrijf te identificeren.',
    'services.ai.solutions.training.title': 'AI Training',
    'services.ai.solutions.training.description': 'Uitgebreide trainingsprogramma\'s om uw team te helpen AI-technologieën te begrijpen en ermee te werken.',
    'services.ai.solutions.strategy.title': 'AI Strategie',
    'services.ai.solutions.strategy.description': 'Ontwikkel een duidelijk stappenplan voor het implementeren van AI-oplossingen in uw organisatie.',
    'services.ai.solutions.automation.title': 'Procesautomatisering',
    'services.ai.solutions.automation.description': 'Stroomlijn operaties met intelligente automatiseringsoplossingen.',
    'services.ai.solutions.agents.title': 'AI Agents op Maat',
    'services.ai.solutions.agents.description': 'Op maat gemaakte AI-agents specifiek ontworpen voor uw bedrijfsbehoeften.',
    'services.ai.solutions.gdpr.title': 'GDPR & ISO 27001 Conform',
    'services.ai.solutions.gdpr.description': 'Volledig conforme AI-oplossingen die uw gegevens en privacy beschermen.',
    'services.ai.solutions.models.title': 'AI Modellen op Maat',
    'services.ai.solutions.models.description': 'Verfijnde en op maat getrainde modellen geoptimaliseerd voor uw specifieke use cases.',
    
    'services.app.solutions.title': 'Onze App Oplossingen',
    'services.app.solutions.mobile.title': 'Mobiele Apps',
    'services.app.solutions.mobile.description': 'Native en cross-platform mobiele applicaties voor iOS en Android.',
    'services.app.solutions.web.title': 'Web Apps',
    'services.app.solutions.web.description': 'Progressive web applicaties met offline mogelijkheden.',
    'services.app.solutions.desktop.title': 'Desktop Apps',
    'services.app.solutions.desktop.description': 'Cross-platform desktop applicaties voor Windows, macOS en Linux.',
    'services.app.solutions.cloud.title': 'Cloud Oplossingen',
    'services.app.solutions.cloud.description': 'Schaalbare cloud-native applicaties met automatische opschaling.',
    'services.app.solutions.security.title': 'Security First',
    'services.app.solutions.security.description': 'Ingebouwde beveiligingsfuncties en best practices.',
    'services.app.solutions.integration.title': 'API Integratie',
    'services.app.solutions.integration.description': 'Naadloze integratie met bestaande systemen en externe diensten.',

    'services.web.solutions.title': 'Onze Web Oplossingen',
    'services.web.solutions.responsive.title': 'Responsive Design',
    'services.web.solutions.responsive.description': 'Websites die perfect werken op alle apparaten en schermformaten.',
    'services.web.solutions.seo.title': 'SEO Optimalisatie',
    'services.web.solutions.seo.description': 'Ingebouwde SEO best practices voor betere zichtbaarheid.',
    'services.web.solutions.performance.title': 'Performance',
    'services.web.solutions.performance.description': 'Bliksemsnelle laadtijden en optimale gebruikerservaring.',
    'services.web.solutions.analytics.title': 'Analytics',
    'services.web.solutions.analytics.description': 'Gedetailleerde inzichten in gebruikersgedrag en website prestaties.',
    'services.web.solutions.accessibility.title': 'Toegankelijkheid',
    'services.web.solutions.accessibility.description': 'WCAG-conforme websites toegankelijk voor alle gebruikers.',
    'services.web.solutions.security.title': 'Beveiliging',
    'services.web.solutions.security.description': 'Beschermd tegen veelvoorkomende web kwetsbaarheden en aanvallen.',
    'services.app.title': 'Snelle App Ontwikkeling',
    'services.app.description': 'Efficiënte app-ontwikkeling aangepast aan uw tijdlijn',
    'services.app.fullDescription': 'Transformeer uw app-concept naar realiteit met ons efficiënte ontwikkelingsproces. Voor eenvoudige applicaties kunnen we binnen twee weken leveren, terwijl complexere oplossingen zorgvuldig worden gepland en uitgevoerd volgens uw specifieke eisen.',
    'services.app.cta': 'Start App Ontwikkeling',
    'services.app.process.title': 'Ons Ontwikkelingsproces',
    'services.app.process.consultation': 'Initiële consultatie en planning',
    'services.app.process.development': 'Snel prototyping en ontwikkeling',
    'services.app.process.deployment': 'Testen en implementatie',
    
    'services.web.title': 'Web Ontwikkeling',
    'services.web.description': 'Op maat gemaakte websites voor uw bedrijfsbehoeften',
    'services.web.fullDescription': 'Krijg een professionele, op maat gemaakte website die uw merk perfect vertegenwoordigt en aan uw zakelijke doelstellingen voldoet.',
    'services.web.cta': 'Start Web Project',
    'services.web.features.title': 'Website Functies',
    'services.web.features.responsive': 'Responsive ontwerp',
    'services.web.features.seo': 'SEO optimalisatie',
    'services.web.features.performance': 'Prestatie optimalisatie',
    
    'services.solutions.view': 'Bekijk Oplossingen',
    'services.solutions.hide': 'Verberg Oplossingen',
    
    'about.title': 'Over AetherFlare',
    'about.description': 'Pionieren in de toekomst van bedrijven door AI-innovatie en digitale transformatie. We combineren geavanceerde technologie met diepgaande industrie-expertise om oplossingen te leveren die echte bedrijfswaarde creëren.',
    'about.mission.title': 'Onze Missie',
    'about.mission.description': 'Bedrijven versterken met intelligente automatisering en AI-oplossingen die duurzame groei, innovatie en concurrentievoordeel stimuleren in het digitale tijdperk.',
    'about.vision.title': 'Onze Visie',
    'about.vision.description': 'De katalysator zijn voor digitale transformatie, waarbij we bedrijven helpen het volledige potentieel van AI en automatisering te benutten om industrieën vorm te geven en blijvende impact te creëren.',
    'about.values.title': 'Onze Waarden',
    'about.values.innovation.title': 'Innovatie Voorop',
    'about.values.innovation.description': 'We verleggen constant grenzen en verkennen nieuwe technologieën om geavanceerde oplossingen te leveren.',
    'about.values.excellence.title': 'Technische Excellentie',
    'about.values.excellence.description': 'We handhaven de hoogste standaarden in onze ontwikkelpraktijken en oplossingslevering.',
    'about.values.partnership.title': 'Echt Partnership',
    'about.values.partnership.description': 'We werken nauw samen met onze klanten om langdurig succes en duurzame groei te waarborgen.',
    'about.team.title': 'Ons Leiderschap',
    'about.team.ai.name': 'Julian Cooper',
    'about.team.ai.role': 'AI Solutions Lead',
    'about.team.ai.description': 'Full-stack developer gespecialiseerd in AI-integratie. Heeft met succes automatiseringsoplossingen geïmplementeerd voor meerdere kleine bedrijven, met focus op praktische AI-toepassingen die echte waarde leveren.',
    'about.team.tech.name': 'Roman Fraikin',
    'about.team.tech.role': 'Technical Lead',
    'about.team.tech.description': 'Full-stack developer met passie voor schone architectuur en efficiënte oplossingen. Heeft verschillende bedrijven geholpen hun technologie te moderniseren en operationele efficiëntie te verbeteren.',
    
    'contact.title': 'Neem Contact Op',
    'contact.description': 'Ontgrendel de kracht van AI Agents: slimme virtuele assistenten die taken automatiseren, klantinteracties verbeteren en bedrijfsprocessen optimaliseren. Onze AI-oplossingen helpen u de efficiëntie en klanttevredenheid te verhogen terwijl uw team zich richt op groei en innovatie.',
    'contact.subtitle': 'Na het indienen van uw gegevens nemen wij contact met u op voor een gratis consultatie om te bespreken hoe AI uw bedrijfsprocessen kan verbeteren, kosten kan verlagen en productiviteit kan verhogen. Samen creëren we oplossingen op maat die perfect aansluiten bij uw behoeften.',
    'contact.simpleForm': 'Snel Contact',
    'contact.questionnaire': 'AI Assessment',
    
    'form.name': 'Naam',
    'form.email': 'E-mail',
    'form.company': 'Bedrijf',
    'form.message': 'Bericht',
    'form.type.label': 'Type Dienst',
    'form.type.ai': 'AI Integratie',
    'form.type.app': 'App Ontwikkeling',
    'form.type.web': 'Web Ontwikkeling',
    'form.type.quickscan': 'Bedrijfs Quickscan',
    'form.submit': 'Verstuur Bericht',
    
    'questionnaire.businessType.label': 'Bedrijfsgrootte',
    'questionnaire.businessType.small': 'Klein Bedrijf (1-50)',
    'questionnaire.businessType.medium': 'Middel Bedrijf (51-250)',
    'questionnaire.businessType.enterprise': 'Groot Bedrijf (251+)',
    
    'questionnaire.aiExperience.label': 'AI Ervaring',
    'questionnaire.aiExperience.none': 'Geen Ervaring',
    'questionnaire.aiExperience.basic': 'Basis Kennis',
    'questionnaire.aiExperience.advanced': 'Gevorderde Implementatie',
    
    'questionnaire.existingAI.label': 'Huidige AI & Automatisering',
    'questionnaire.existingAI.placeholder': 'Beschrijf welke AI-tools of geautomatiseerde processen u momenteel gebruikt',
    
    'questionnaire.timeline.label': 'Project Planning',
    'questionnaire.timeline.urgent': 'Urgent (< 1 maand)',
    'questionnaire.timeline.normal': 'Normaal (1-3 maanden)',
    'questionnaire.timeline.flexible': 'Flexibel (3+ maanden)',
    
    'questionnaire.processes.label': 'Huidige Bedrijfsprocessen',
    'questionnaire.processes.help': 'Selecteer alle relevante opties',
    'questionnaire.processes.sales': 'Verkoop & CRM',
    'questionnaire.processes.customer': 'Klantenservice',
    'questionnaire.processes.operations': 'Operaties',
    'questionnaire.processes.hr': 'HR & Werving',
    'questionnaire.processes.finance': 'Financiën & Boekhouding',
    'questionnaire.businessDescription': 'Bedrijfsomschrijving',
    'questionnaire.businessDescription.placeholder': 'Beschrijf uw bedrijfsactiviteiten, belangrijkste producten/diensten en kernprocessen in detail',
    
    'questionnaire.additionalInfo': 'Aanvullende Informatie',
    'questionnaire.submit': 'Assessment Versturen',
    
    'footer.services': 'Diensten',
    'footer.company': 'Bedrijf',
    'footer.rights': 'Alle rechten voorbehouden.',
    
    'form.success': 'Bericht succesvol verzonden!',
    'form.error': 'Verzenden mislukt. Probeer het opnieuw.',
    
    'cookie.title': 'Wij waarderen uw privacy',
    'cookie.description': 'We gebruiken alleen analytische cookies om te begrijpen hoe onze site wordt gebruikt - geen marketing, geen tracking, geen advertenties. U kunt gerust weigeren, de site werkt perfect zonder.',
    'cookie.accept': 'Accepteren',
    'cookie.decline': 'Weigeren',
    'cookie.learnMore': 'Meer informatie',

    'accessibility.skipToContent': 'Ga naar hoofdinhoud',
    'accessibility.logoHomeLink': 'Ga naar homepage',
    'accessibility.mainNavigation': 'Hoofdnavigatie',
    'accessibility.footerNavigation': 'Voetnavigatie',

     'form.privacyStatement': 'Door dit formulier te versturen, ga je ermee akkoord dat we je persoonsgegevens verwerken om te reageren op je vraag, in overeenstemming met de Europese privacywetgeving (AVG). We verwijderen contactgegevens 6 maanden na inzending.'
  },
}