import { Helmet } from 'react-helmet-async';
import { useSEO } from '../utils/seo/useSEO';
import { useLocation } from 'react-router-dom';
import { metaKeywords } from '../utils/seo/meta';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  schema?: object;
}

export const SEO = ({ schema, ...props }: SEOProps) => {
  const { seo, jsonLd } = useSEO(props);
  const { pathname } = useLocation();
  const currentUrl = `https://aetherflare.nl${pathname}`;

  return (
    <Helmet>
      {/* Basic */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <link rel="canonical" href={currentUrl} />
      
      {/* Keywords and additional meta tags */}
      <meta name="keywords" content={metaKeywords.join(', ')} />
      <meta name="author" content="AetherFlare" />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      
      {/* PWA */}
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#DC2625" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="application-name" content="AetherFlare" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="AetherFlare" />
      
      {/* Language alternates */}
      <link rel="alternate" hrefLang="en" href={`${currentUrl}`} />
      <link rel="alternate" hrefLang="nl" href={`${currentUrl}`} />
      <link rel="alternate" hrefLang="x-default" href={`${currentUrl}`} />

      {/* Open Graph */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:site_name" content="AetherFlare" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:locale:alternate" content="nl_NL" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@aetherflare" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      {/* JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(schema || jsonLd)}
      </script>
    </Helmet>
  );
};