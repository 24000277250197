import { ReactNode } from 'react';
import { usePageTracking } from '../utils/analytics';

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  usePageTracking();
  return <>{children}</>;
};