import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../utils/analytics';

const schema = z.object({
  businessType: z.enum(['small', 'medium', 'enterprise']),
  currentProcesses: z.array(z.string()).min(1),
  aiExperience: z.enum(['none', 'basic', 'advanced']),
  existingAI: z.string(),
  businessDescription: z.string().min(10),
  timeline: z.enum(['urgent', 'normal', 'flexible']),
  additionalInfo: z.string(),
});

type FormData = z.infer<typeof schema>;

const processes = [
  'sales',
  'customer',
  'operations',
  'hr',
  'finance',
] as const;

interface QuestionnaireFormProps {
  initialService?: 'ai' | 'app' | 'web';
}

export const QuestionnaireForm: React.FC<QuestionnaireFormProps> = ({ initialService }) => {
  const { t } = useTranslation();

  // Note: If you'd like to add an "email" field, modify the schema above and your form here.
  // For now, we keep everything as is, just changing the endpoint to /api/email/questionnaire
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      // If you want to track initialService, add a field in the schema or just keep for logic
      // type: initialService || 'ai'
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      analytics.trackEvent({
        type: 'form_submit',
        name: 'questionnaire_form',
        properties: {
          businessType: data.businessType,
          aiExperience: data.aiExperience,
          timeline: data.timeline
        }
      });

      // --- CHANGE HERE: Post to /api/email/questionnaire instead of /api/email/send
      const response = await fetch('/api/email/questionnaire', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.success) {
        alert('Email sent successfully!');
        reset();
        analytics.trackEvent({
          type: 'form_submit',
          name: 'questionnaire_form_success'
        });
      } else {
        alert('Failed to send email. Please try again later.');
        analytics.trackEvent({
          type: 'error',
          name: 'questionnaire_form_error',
          properties: {
            error: 'API Error'
          }
        });
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please check your network connection.');
      analytics.trackEvent({
        type: 'error',
        name: 'questionnaire_form_error',
        properties: {
          error: error instanceof Error ? error.message : 'Unknown error'
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 max-w-xl mx-auto">
      {/* Business Type */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('questionnaire.businessType.label')}
        </label>
        <select
          {...register('businessType')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500
                     dark:bg-gray-800 dark:border-gray-700"
        >
          <option value="small">{t('questionnaire.businessType.small')}</option>
          <option value="medium">{t('questionnaire.businessType.medium')}</option>
          <option value="enterprise">{t('questionnaire.businessType.enterprise')}</option>
        </select>
        {errors.businessType && (
          <p className="mt-1 text-sm text-red-600">{errors.businessType.message}</p>
        )}
      </div>

      {/* Current Processes */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('questionnaire.processes.label')}
        </label>
        <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
          {t('questionnaire.processes.help')}
        </p>
        <div className="space-y-2">
          {processes.map((process) => (
            <label key={process} className="flex items-center">
              <input
                type="checkbox"
                {...register('currentProcesses')}
                value={process}
                className="rounded border-gray-300 text-red-600 focus:ring-red-500
                           dark:border-gray-700"
              />
              <span className="ml-2 text-gray-700 dark:text-gray-300">
                {t(`questionnaire.processes.${process}`)}
              </span>
            </label>
          ))}
        </div>
        {errors.currentProcesses && (
          <p className="mt-1 text-sm text-red-600">{errors.currentProcesses.message}</p>
        )}
      </div>

      {/* AI Experience */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('questionnaire.aiExperience.label')}
        </label>
        <select
          {...register('aiExperience')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500
                     dark:bg-gray-800 dark:border-gray-700"
        >
          <option value="none">{t('questionnaire.aiExperience.none')}</option>
          <option value="basic">{t('questionnaire.aiExperience.basic')}</option>
          <option value="advanced">{t('questionnaire.aiExperience.advanced')}</option>
        </select>
        {errors.aiExperience && (
          <p className="mt-1 text-sm text-red-600">{errors.aiExperience.message}</p>
        )}
      </div>

      {/* Existing AI & Automation */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('questionnaire.existingAI.label')}
        </label>
        <textarea
          {...register('existingAI')}
          rows={4}
          placeholder={t('questionnaire.existingAI.placeholder')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500 dark:bg-gray-800
                     dark:border-gray-700"
        />
        {errors.existingAI && (
          <p className="mt-1 text-sm text-red-600">{errors.existingAI.message}</p>
        )}
      </div>

      {/* Business Description */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('questionnaire.businessDescription')}
        </label>
        <textarea
          {...register('businessDescription')}
          rows={4}
          placeholder={t('questionnaire.businessDescription.placeholder')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500 dark:bg-gray-800
                     dark:border-gray-700"
        />
        {errors.businessDescription && (
          <p className="mt-1 text-sm text-red-600">
            {errors.businessDescription.message}
          </p>
        )}
      </div>

      {/* Timeline */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('questionnaire.timeline.label')}
        </label>
        <select
          {...register('timeline')}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500 dark:bg-gray-800
                     dark:border-gray-700"
        >
          <option value="urgent">{t('questionnaire.timeline.urgent')}</option>
          <option value="normal">{t('questionnaire.timeline.normal')}</option>
          <option value="flexible">{t('questionnaire.timeline.flexible')}</option>
        </select>
        {errors.timeline && (
          <p className="mt-1 text-sm text-red-600">{errors.timeline.message}</p>
        )}
      </div>

      {/* Additional Information */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('questionnaire.additionalInfo')}
        </label>
        <textarea
          {...register('additionalInfo')}
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm
                     focus:border-red-500 focus:ring-red-500 dark:bg-gray-800
                     dark:border-gray-700"
        />
        {errors.additionalInfo && (
          <p className="mt-1 text-sm text-red-600">{errors.additionalInfo.message}</p>
        )}
      </div>

      <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent
                   rounded-md shadow-sm text-sm font-medium text-white bg-red-600
                   hover:bg-red-700 focus:outline-none focus:ring-2
                   focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-offset-gray-900"
      >
        {t('questionnaire.submit')}
      </button>
    </form>
  );
};
