import { useTranslation } from 'react-i18next';
import { Brain, Rocket, Globe, ArrowRight, Clock, Target, TrendingUp, Map } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { SEO } from '../components/SEO';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

export const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        schema={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          speakable: {
            '@type': 'SpeakableSpecification',
            cssSelector: ['h1', '.hero-description']
          },
          mainEntity: {
            '@type': 'Organization',
            name: 'AetherFlare',
            description: t('hero.subtitle'),
            url: 'https://aetherflare.nl'
          }
        }}
      />
      <section className="relative pt-24 pb-20 bg-gradient-to-b from-gray-50 to-white dark:from-gray-800 dark:to-gray-900 overflow-hidden">
        {/* Animated background elements */}
        <motion.div
          className="absolute inset-0 -z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 2 }}
        >
          <div className="absolute top-20 left-20 w-72 h-72 bg-red-200 dark:bg-red-900/20 rounded-full mix-blend-multiply filter blur-xl animate-blob" />
          <div className="absolute top-40 right-20 w-72 h-72 bg-blue-200 dark:bg-blue-900/20 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000" />
          <div className="absolute -bottom-8 left-1/2 w-72 h-72 bg-green-200 dark:bg-green-900/20 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000" />
        </motion.div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.div
            initial="initial"
            animate="animate"
            variants={stagger}
            className="relative z-10"
          >
            <motion.h1
              variants={fadeIn}
              className="text-4xl sm:text-5xl font-bold text-gray-900 dark:text-white mb-6"
            >
              {t('hero.title')}
            </motion.h1>
            <motion.p
              variants={fadeIn}
              className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto"
            >
              {t('hero.subtitle')}
            </motion.p>
            <motion.div variants={fadeIn}>
              <Link
                to="/contact"
                className="group inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 transition-all hover:scale-105 hover:shadow-lg"
              >
                {t('hero.cta')}
                <ArrowRight className="ml-2 w-4 h-4 transition-transform group-hover:translate-x-1" />
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* AI Benefits Section */}
      <section className="py-24 bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-800 relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            variants={stagger}
            className="text-center mb-16"
          >
            <motion.h2
              variants={fadeIn}
              className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-white mb-6"
            >
              {t('ai.transform.title')}
            </motion.h2>
            <motion.p
              variants={fadeIn}
              className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
            >
              {t('ai.transform.subtitle')}
            </motion.p>
          </motion.div>

          <motion.div
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            variants={stagger}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12"
          >
            <motion.div
              variants={fadeIn}
              whileHover={{ scale: 1.05 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-red-100 to-red-50 dark:from-red-900/20 dark:to-red-800/20 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity" />
              <Clock className="w-12 h-12 text-red-600 mb-4 relative" />
              <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white relative">
                {t('ai.benefits.time')}
              </h3>
            </motion.div>

            <motion.div
              variants={fadeIn}
              whileHover={{ scale: 1.05 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-red-100 to-red-50 dark:from-red-900/20 dark:to-red-800/20 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity" />
              <Target className="w-12 h-12 text-red-600 mb-4 relative" />
              <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white relative">
                {t('ai.benefits.focus')}
              </h3>
            </motion.div>

            <motion.div
              variants={fadeIn}
              whileHover={{ scale: 1.05 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-red-100 to-red-50 dark:from-red-900/20 dark:to-red-800/20 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity" />
              <TrendingUp className="w-12 h-12 text-red-600 mb-4 relative" />
              <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white relative">
                {t('ai.benefits.scale')}
              </h3>
            </motion.div>

            <motion.div
              variants={fadeIn}
              whileHover={{ scale: 1.05 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-red-100 to-red-50 dark:from-red-900/20 dark:to-red-800/20 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity" />
              <Map className="w-12 h-12 text-red-600 mb-4 relative" />
              <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white relative">
                {t('ai.benefits.roadmap')}
              </h3>
            </motion.div>
          </motion.div>

          <motion.div
            variants={fadeIn}
            className="text-center"
          >
            <Link
              to="/contact?service=ai"
              className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-md text-white bg-red-600 hover:bg-red-700 transition-all hover:scale-105 hover:shadow-lg"
            >
              {t('ai.benefits.cta')}
              <ArrowRight className="ml-2 w-5 h-5" />
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-white dark:bg-gray-900 relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            variants={stagger}
            className="grid grid-cols-1 md:grid-cols-3 gap-8"
          >
            <motion.div
              variants={fadeIn}
              whileHover={{ scale: 1.02 }}
              className="p-6 bg-gray-50 dark:bg-dark-card rounded-lg hover:shadow-lg transition-all group"
            >
              <div className="relative">
                <div className="absolute inset-0 bg-red-100 dark:bg-red-900/20 rounded-full scale-150 blur-xl transition-opacity opacity-0 group-hover:opacity-70" />
                <Brain className="w-12 h-12 text-red-600 mb-4 relative transition-transform group-hover:scale-110" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">
                {t('services.ai.title')}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {t('services.ai.description')}
              </p>
              <Link
                to="/services#ai"
                className="mt-4 inline-flex items-center text-red-600 hover:text-red-700"
              >
                {t('services.ai.cta')} <ArrowRight className="ml-1 w-4 h-4" />
              </Link>
            </motion.div>
            <motion.div
              variants={fadeIn}
              whileHover={{ scale: 1.02 }}
              className="p-6 bg-gray-50 dark:bg-dark-card rounded-lg hover:shadow-lg transition-all group"
            >
              <div className="relative">
                <div className="absolute inset-0 bg-red-100 dark:bg-red-900/20 rounded-full scale-150 blur-xl transition-opacity opacity-0 group-hover:opacity-70" />
                <Rocket className="w-12 h-12 text-red-600 mb-4 relative transition-transform group-hover:scale-110" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">
                {t('services.app.title')}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {t('services.app.description')}
              </p>
              <Link
                to="/services#app"
                className="mt-4 inline-flex items-center text-red-600 hover:text-red-700"
              >
                {t('services.app.cta')} <ArrowRight className="ml-1 w-4 h-4" />
              </Link>
            </motion.div>
            <motion.div
              variants={fadeIn}
              whileHover={{ scale: 1.02 }}
              className="p-6 bg-gray-50 dark:bg-dark-card rounded-lg hover:shadow-lg transition-all group"
            >
              <div className="relative">
                <div className="absolute inset-0 bg-red-100 dark:bg-red-900/20 rounded-full scale-150 blur-xl transition-opacity opacity-0 group-hover:opacity-70" />
                <Globe className="w-12 h-12 text-red-600 mb-4 relative transition-transform group-hover:scale-110" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">
                {t('services.web.title')}
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                {t('services.web.description')}
              </p>
              <Link
                to="/services#web"
                className="mt-4 inline-flex items-center text-red-600 hover:text-red-700"
              >
                {t('services.web.cta')} <ArrowRight className="ml-1 w-4 h-4" />
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>
    </>
  );
};