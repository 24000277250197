import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SEO } from '../../components/SEO';
import { QuestionnaireForm } from './QuestionnaireForm';
import { useBreadcrumbSchema } from '../../utils/seo/breadcrumbs';
import { ContactForm } from '../../components/ContactForm';

export const Contact = () => {
  const { t } = useTranslation();
  const breadcrumbSchema = useBreadcrumbSchema();
  const [searchParams] = useSearchParams();
  const [formType, setFormType] = useState<'contact' | 'questionnaire'>('contact');
  const [selectedService, setSelectedService] = useState<'ai' | 'app' | 'web' | undefined>();

  useEffect(() => {
    const service = searchParams.get('service') as 'ai' | 'app' | 'web' | undefined;
    if (service) {
      setSelectedService(service);
    }
  }, [searchParams]);

  return (
    <div className="pt-24 pb-16">
      <SEO 
        title={t('contact.title')}
        description={t('contact.description')}
        schema={breadcrumbSchema}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white">
            {t('contact.title')}
          </h1>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            {t('contact.description')}
          </p>
          <p className="mt-4 text-sm text-gray-500 dark:text-gray-400 max-w-2xl mx-auto">
            {t('contact.subtitle')}
          </p>
        </div>
        
        <div className="flex justify-center space-x-4 mb-8">
          <button
            onClick={() => setFormType('contact')}
            className={`px-4 py-2 rounded-md ${
              formType === 'contact'
                ? 'bg-red-600 text-white'
                : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'
            }`}
          >
            {t('contact.simpleForm')}
          </button>
          <button
            onClick={() => setFormType('questionnaire')}
            className={`px-4 py-2 rounded-md ${
              formType === 'questionnaire'
                ? 'bg-red-600 text-white'
                : 'bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300'
            }`}
          >
            {t('contact.questionnaire')}
          </button>
        </div>

        {formType === 'contact' ? (
          <ContactForm initialService={selectedService} />
        ) : (
          <QuestionnaireForm initialService={selectedService} />
        )}
      </div>
    </div>
  );
};
