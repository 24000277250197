import { Moon, Sun } from 'lucide-react';
import { useDarkMode } from '../hooks/useDarkMode';
import { analytics } from '../utils/analytics';

export const ThemeToggle = () => {
  const [isDark, setIsDark] = useDarkMode();

  const handleThemeToggle = () => {
    analytics.trackEvent({
      type: 'click',
      name: 'theme_toggle',
      properties: {
        from: isDark ? 'dark' : 'light',
        to: isDark ? 'light' : 'dark'
      }
    });
    setIsDark(!isDark);
  };

  return (
    <button
      onClick={handleThemeToggle}
      aria-label={`Switch to ${isDark ? 'light' : 'dark'} theme`}
      className="p-2 rounded-lg text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-dark-card transition-colors"
    >
      {isDark ? (
        <Sun className="w-5 h-5" />
      ) : (
        <Moon className="w-5 h-5" />
      )}
    </button>
  );
};