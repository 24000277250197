export const generateOrganizationSchema = () => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'AetherFlare',
  url: 'https://aetherflare.nl',
  logo: 'https://aetherflare.nl/logo.svg',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Bodegraven',
    addressCountry: 'NL'
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer service',
    availableLanguage: ['en', 'nl']
  },
  sameAs: [
    'https://www.linkedin.com/company/101116087'
  ]
});

export const generateServiceSchema = (service: 'ai' | 'app' | 'web') => {
  const services = {
    ai: {
      name: 'AI Integration Services',
      description: 'Enterprise AI integration and automation solutions'
    },
    app: {
      name: 'App Development Services',
      description: 'Custom application development for businesses'
    },
    web: {
      name: 'Web Development Services',
      description: 'Professional web development solutions'
    }
  };

  return {
    '@context': 'https://schema.org',
    '@type': 'Service',
    '@id': `https://aetherflare.nl/services#${service}`,
    serviceType: services[service].name,
    description: services[service].description,
    areaServed: {
      '@type': 'Country',
      name: 'Netherlands',
      alternateName: 'Nederland'
    },
    provider: {
      '@type': 'Organization',
      name: 'AetherFlare',
      legalName: 'AetherFlare',
      vatID: 'KVK-nummer: 92168876',
      location: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Bodegraven',
          addressRegion: 'Zuid-Holland',
          addressCountry: 'NL'
        }
      }
    }
  };
};