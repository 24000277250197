import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Logo } from './Logo';
import { memo } from 'react';

export const Footer = memo(() => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  const companyInfo = {
    kvk: 'KVK-nummer: 92168876',
    location: 'Bodegraven, Netherlands',
    linkedin: 'https://www.linkedin.com/company/101116087',
  };

  return (
    <footer 
      className="relative bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800 mt-auto"
      role="contentinfo"
    >
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-50/50 to-gray-50 dark:via-gray-900/50 dark:to-gray-900 opacity-50" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="relative grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <Link to="/" className="flex items-center">
              <Logo className="w-8 h-8" />
              <span className="ml-2 text-xl font-bold text-gray-900 dark:text-dark-text">
                AetherFlare
              </span>
            </Link>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-dark-text uppercase tracking-wider">
              {t('footer.services')}
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link 
                  to="/services#ai" 
                  className="text-gray-600 dark:text-dark-muted hover:text-red-600 dark:hover:text-red-500"
                >
                  {t('services.ai.title')}
                </Link>
              </li>
              <li>
                <Link 
                  to="/services#app" 
                  className="text-gray-600 dark:text-dark-muted hover:text-red-600 dark:hover:text-red-500"
                >
                  {t('services.app.title')}
                </Link>
              </li>
              <li>
                <Link 
                  to="/services#web" 
                  className="text-gray-600 dark:text-dark-muted hover:text-red-600 dark:hover:text-red-500"
                >
                  {t('services.web.title')}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-dark-text uppercase tracking-wider">
              {t('footer.company')}
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link 
                  to="/about" 
                  className="text-gray-600 dark:text-dark-muted hover:text-red-600 dark:hover:text-red-500"
                >
                  {t('nav.about')}
                </Link>
              </li>
              <li>
                <Link 
                  to="/contact" 
                  className="text-gray-600 dark:text-dark-muted hover:text-red-600 dark:hover:text-red-500"
                >
                  {t('nav.contact')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="relative mt-8 pt-8 border-t border-gray-200/50 dark:border-gray-700/50 flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex flex-col items-center md:items-start">
            <p className="text-gray-500 dark:text-dark-muted">
              © {year} AetherFlare. {t('footer.rights')}
            </p>
            <p className="text-sm text-gray-500 dark:text-dark-muted mt-1">
              {companyInfo.location} • {companyInfo.kvk}
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <a
              href={companyInfo.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-red-600 dark:text-dark-muted dark:hover:text-red-500 transition-colors"
              aria-label="Follow us on LinkedIn"
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';