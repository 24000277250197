import React from 'react';
import { useTranslation } from 'react-i18next';
import { Languages } from 'lucide-react';
import { analytics } from '../utils/analytics';

export const LanguageSwitch: React.FC = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'nl' : 'en';
    analytics.trackEvent({
      type: 'click',
      name: 'language_switch',
      properties: {
        from: i18n.language,
        to: newLang
      }
    });
    i18n.changeLanguage(newLang);
  };

  return (
    <button
      onClick={toggleLanguage}
      aria-label={`Change language to ${i18n.language === 'en' ? 'Dutch' : 'English'}`}
      className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-500 transition-colors rounded-md hover:bg-gray-50 dark:hover:bg-gray-800"
    >
      <Languages className="w-5 h-5" />
      <span className="uppercase text-sm font-medium">{i18n.language}</span>
    </button>
  );
};