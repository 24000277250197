export default {
  translation: {
    'nav.home': 'Home',
    'nav.services': 'Services',
    'nav.about': 'About',
    'nav.contact': 'Contact',
    
    'hero.title': 'Transforming Businesses Through AI & Automation',
    'hero.subtitle': 'Discover the potential of AI in your business with our expert consultation and development services',
    'hero.cta': 'Get Started',
    
    'ai.transform.title': 'Transform Your Business with AI-Powered Automation',
    'ai.transform.subtitle': 'We build your digital workforce, so you can focus on what really matters. Get your free AI-Scan report to discover how automation can transform your operations.',
    'ai.benefits.title': 'Why Choose AI Automation?',
    'ai.benefits.time': 'Save time and reduce costs',
    'ai.benefits.focus': 'Focus on what really matters',
    'ai.benefits.scale': 'Scale your business without extra staff',
    'ai.benefits.roadmap': 'Get a free personalized implementation roadmap',
    'ai.benefits.cta': 'Get Your Free AI-Scan',
    
    'services.title': 'Our Services',
    'services.ai.title': 'AI Integration',
    'services.ai.description': 'We analyze your business processes to find optimal AI implementation opportunities',
    'services.ai.fullDescription': 'Transform your business operations with cutting-edge AI solutions tailored to your specific needs. Our expert team analyzes your processes and identifies key areas where AI can make the biggest impact.',
    'services.ai.cta': 'Start AI Integration',
    'services.ai.benefits.title': 'Benefits of AI Integration',
    'services.ai.benefits.analysis': 'Automated data analysis and insights',
    'services.ai.benefits.processes': 'Streamlined business processes',
    'services.ai.benefits.decisions': 'Enhanced decision making',
    'services.ai.solutions.title': 'Our AI Solutions',
    'services.ai.solutions.consultation.title': 'Expert Consultation',
    'services.ai.solutions.consultation.description': 'Get personalized guidance from our AI experts to identify the best opportunities for your business.',
    'services.ai.solutions.training.title': 'AI Training',
    'services.ai.solutions.training.description': 'Comprehensive training programs to help your team understand and work with AI technologies.',
    'services.ai.solutions.strategy.title': 'AI Strategy',
    'services.ai.solutions.strategy.description': 'Develop a clear roadmap for implementing AI solutions across your organization.',
    'services.ai.solutions.automation.title': 'Process Automation',
    'services.ai.solutions.automation.description': 'Streamline operations with intelligent automation solutions.',
    'services.ai.solutions.agents.title': 'Custom AI Agents',
    'services.ai.solutions.agents.description': 'Tailored AI agents designed specifically for your business needs.',
    'services.ai.solutions.gdpr.title': 'GDPR & ISO 27001 Compliant',
    'services.ai.solutions.gdpr.description': 'Fully compliant AI solutions that protect your data and privacy.',
    'services.ai.solutions.models.title': 'Custom AI Models',
    'services.ai.solutions.models.description': 'Fine-tuned and custom-trained models optimized for your specific use cases.',
    
    'services.app.solutions.title': 'Our App Solutions',
    'services.app.solutions.mobile.title': 'Mobile Apps',
    'services.app.solutions.mobile.description': 'Native and cross-platform mobile applications for iOS and Android.',
    'services.app.solutions.web.title': 'Web Apps',
    'services.app.solutions.web.description': 'Progressive web applications with offline capabilities.',
    'services.app.solutions.desktop.title': 'Desktop Apps',
    'services.app.solutions.desktop.description': 'Cross-platform desktop applications for Windows, macOS, and Linux.',
    'services.app.solutions.cloud.title': 'Cloud Solutions',
    'services.app.solutions.cloud.description': 'Scalable cloud-native applications with automatic scaling.',
    'services.app.solutions.security.title': 'Security First',
    'services.app.solutions.security.description': 'Built-in security features and best practices.',
    'services.app.solutions.integration.title': 'API Integration',
    'services.app.solutions.integration.description': 'Seamless integration with existing systems and third-party services.',

    'services.web.solutions.title': 'Our Web Solutions',
    'services.web.solutions.responsive.title': 'Responsive Design',
    'services.web.solutions.responsive.description': 'Websites that work perfectly on all devices and screen sizes.',
    'services.web.solutions.seo.title': 'SEO Optimization',
    'services.web.solutions.seo.description': 'Built-in SEO best practices for better visibility.',
    'services.web.solutions.performance.title': 'Performance',
    'services.web.solutions.performance.description': 'Lightning-fast loading times and optimal user experience.',
    'services.web.solutions.analytics.title': 'Analytics',
    'services.web.solutions.analytics.description': 'Detailed insights into user behavior and website performance.',
    'services.web.solutions.accessibility.title': 'Accessibility',
    'services.web.solutions.accessibility.description': 'WCAG compliant websites accessible to all users.',
    'services.web.solutions.security.title': 'Security',
    'services.web.solutions.security.description': 'Protected against common web vulnerabilities and attacks.',
    'services.app.title': 'Rapid App Development',
    'services.app.description': 'Efficient app development tailored to your timeline',
    'services.app.fullDescription': 'Transform your app concept into reality with our efficient development process. For straightforward applications, we can deliver in as little as two weeks, while more complex solutions are carefully planned and executed according to your specific requirements.',
    'services.app.cta': 'Start App Development',
    'services.app.process.title': 'Our Development Process',
    'services.app.process.consultation': 'Initial consultation and planning',
    'services.app.process.development': 'Rapid prototyping and development',
    'services.app.process.deployment': 'Testing and deployment',
    
    'services.web.title': 'Web Development',
    'services.web.description': 'Custom websites tailored to your business needs',
    'services.web.fullDescription': 'Get a professional, custom-built website that perfectly represents your brand and meets your business objectives.',
    'services.web.cta': 'Start Web Project',
    'services.web.features.title': 'Website Features',
    'services.web.features.responsive': 'Responsive design',
    'services.web.features.seo': 'SEO optimization',
    'services.web.features.performance': 'Performance optimization',
    
    'services.solutions.view': 'View Solutions',
    'services.solutions.hide': 'Hide Solutions',
    
    'about.title': 'About AetherFlare',
    'about.description': 'Pioneering the future of business through AI innovation and digital transformation. We combine cutting-edge technology with deep industry expertise to deliver solutions that drive real business value.',
    'about.mission.title': 'Our Mission',
    'about.mission.description': 'To empower businesses with intelligent automation and AI solutions that drive sustainable growth, innovation, and competitive advantage in the digital age.',
    'about.vision.title': 'Our Vision',
    'about.vision.description': 'To be the catalyst for digital transformation, helping businesses harness the full potential of AI and automation to reshape industries and create lasting impact.',
    'about.values.title': 'Our Values',
    'about.values.innovation.title': 'Innovation First',
    'about.values.innovation.description': 'We constantly push boundaries and explore new technologies to deliver cutting-edge solutions.',
    'about.values.excellence.title': 'Technical Excellence',
    'about.values.excellence.description': 'We maintain the highest standards in our development practices and solution delivery.',
    'about.values.partnership.title': 'True Partnership',
    'about.values.partnership.description': 'We work closely with our clients to ensure long-term success and sustainable growth.',
    'about.team.title': 'Our Leadership',
    'about.team.ai.name': 'Julian Cooper',
    'about.team.ai.role': 'AI Solutions Lead',
    'about.team.ai.description': 'Full-stack developer specializing in AI integration. Successfully implemented automation solutions for multiple small businesses, focusing on practical AI applications that deliver real value.',
    'about.team.tech.name': 'Roman Fraikin',
    'about.team.tech.role': 'Technical Lead',
    'about.team.tech.description': 'Full-stack developer with a passion for clean architecture and efficient solutions. Has helped several companies modernize their tech stack and improve operational efficiency.',
    
    'contact.title': 'Contact Us',
    'contact.description': 'Unleash the power of AI Agents: smart virtual assistants that automate tasks, improve customer interactions, and optimize your business processes. Our AI solutions help you increase efficiency and customer satisfaction while your team focuses on growth and innovation.',
    'contact.subtitle': 'After submitting your details, we will contact you for a free consultation to discuss how AI can improve your business processes, reduce costs, and increase productivity. Together, we will create tailored solutions that perfectly meet your needs.',
    'contact.simpleForm': 'Quick Contact',
    'contact.questionnaire': 'AI Assessment',
    
    'form.name': 'Name',
    'form.email': 'Email',
    'form.company': 'Company',
    'form.message': 'Message',
    'form.type.label': 'Service Type',
    'form.type.ai': 'AI Integration',
    'form.type.app': 'App Development',
    'form.type.web': 'Web Development',
    'form.type.quickscan': 'Business Quickscan',
    'form.submit': 'Send Message',
    
    'questionnaire.businessType.label': 'Business Size',
    'questionnaire.businessType.small': 'Small Business (1-50)',
    'questionnaire.businessType.medium': 'Medium Business (51-250)',
    'questionnaire.businessType.enterprise': 'Enterprise (251+)',
    
    'questionnaire.aiExperience.label': 'AI Experience Level',
    'questionnaire.aiExperience.none': 'No Experience',
    'questionnaire.aiExperience.basic': 'Basic Understanding',
    'questionnaire.aiExperience.advanced': 'Advanced Implementation',
    
    'questionnaire.existingAI.label': 'Current AI & Automation',
    'questionnaire.existingAI.placeholder': 'Describe any AI tools or automated processes you currently use',
    
    'questionnaire.timeline.label': 'Project Timeline',
    'questionnaire.timeline.urgent': 'Urgent (< 1 month)',
    'questionnaire.timeline.normal': 'Normal (1-3 months)',
    'questionnaire.timeline.flexible': 'Flexible (3+ months)',
    
    'questionnaire.processes.label': 'Current Business Processes',
    'questionnaire.processes.help': 'Select all that apply',
    'questionnaire.processes.sales': 'Sales & CRM',
    'questionnaire.processes.customer': 'Customer Service',
    'questionnaire.processes.operations': 'Operations',
    'questionnaire.processes.hr': 'HR & Recruitment',
    'questionnaire.processes.finance': 'Finance & Accounting',
    'questionnaire.businessDescription': 'Business Description',
    'questionnaire.businessDescription.placeholder': 'Please describe your business activities, main products/services, and key operations in detail',
    
    'questionnaire.additionalInfo': 'Additional Information',
    'questionnaire.submit': 'Submit Assessment',
    
    'footer.services': 'Services',
    'footer.company': 'Company',
    'footer.rights': 'All rights reserved.',
    
    'form.success': 'Message sent successfully!',
    'form.error': 'Failed to send message. Please try again.',
    
    'cookie.title': 'We value your privacy',
    'cookie.description': 'We use analytics cookies only to understand how our site is used - no marketing, no tracking across sites, no ads. Feel free to decline, the site works perfectly without them.',
    'cookie.accept': 'Accept',
    'cookie.decline': 'Decline',
    'cookie.learnMore': 'Learn more',
    
    'accessibility.skipToContent': 'Skip to main content',
    'accessibility.logoHomeLink': 'Go to homepage',
    'accessibility.mainNavigation': 'Main navigation',
    'accessibility.footerNavigation': 'Footer navigation',

    'form.privacyStatement': 'By submitting this form, you agree that we will process your personal data to respond to your inquiry in compliance with EU privacy laws (GDPR). We will delete contact information 6 months after submission.'
  
  },
}